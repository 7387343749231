import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Login(props) {

    const [inputValues, setInputValue] = useState({
        email: "",
        password: "",
      });
    
      const [validation, setValidation] = useState({
        email: "",
        password: "",
        subm: "",
      });
      const navigate = useNavigate();
    
      //handle submit updates
      function handleChange(event) {
        const { name, value } = event.target;
        setInputValue({ ...inputValues, [name]: value });
      }
    
      const checkValidation = () => {
        let errors = validation;
    
        // email validation
        const emailCond = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$";
        if (!inputValues.email.trim()) {
          errors.email = "Email is required";
          errors.subm = "1";
        } else if (!inputValues.email.match(emailCond)) {
          errors.email = "Please enter a valid email address";
          errors.subm = "1";
        } else {
          errors.email = "";
          errors.subm = "";
        }
    
        //password validation
        const password = inputValues.password;
        if (!password) {
          errors.password = "password is required";
          errors.subm = "1";
        } else {
          errors.password = "";
          errors.subm = "";
        }
    
        setValidation(errors);
      };
    
      useEffect(() => {
        checkValidation();
        if(sessionStorage.getItem('email')!==undefined)
        {
          navigate('/color');
        }
        if(sessionStorage.getItem('email')===null)
          {
            navigate('/login');
          }
    
      }, [inputValues,props]);

  return (
    <>
    <div className="humanbeing">
   
    <div className="banner-slider">
        <div className="container">
            <div className="section" id="fufufufu">
            {props.mesg !== "" && (
          <div className={`alert alert-${props.mesg.typ}`} role="alert">
            {props.mesg.msg}
          </div>
        )}
                <form
          method="POST"
          onSubmit={props.loginUser}
        >
          <div className="mb-3">
            <input
              placeholder="email"
              type="email"
              name="email"
              id="email"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.email}
            />
          </div>
          {validation.email && (
            <p style={{ color: "red" }}>{validation.email}</p>
          )}

          <div className="mb-3">
            <input
              placeholder="password"
              type="password"
              name="password"
              id="password"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.password}
              required
            />
            {validation.password && (
              <p style={{ color: "red" }}>{validation.password}</p>
            )}
          </div>

          <span className="form-input-login">
            Not have an account? Click <Link to="/register">here</Link> to
            register
          </span>
          <br />
          <button
            type="submit"
            id="submit-button"
            className="btn btn-primary"
            onClick={handleChange}
          >
            Submit
          </button>
        </form>
            </div>
        </div>
    </div>

   
    </div> 
    </>
  )
}
