import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function Header(props) {
  return (
    <>
    <div className="humanbeing">
          <div className="header">
        <div className="container">
            <div className="row" style={{alignItems:"center"}}>
                <div className="col-md-6 col-6">
                    <div className="logo">
                    <Link to="/"><img src="img/2707jewellery-logo.png" alt=""/></Link>
                    </div>
                </div>

                <div className="col-md-6 col-6">
                    <div className="mainse" style={{display:"flex",justifyContent:"end"}}>

                    {(sessionStorage.getItem('email') === null || sessionStorage.getItem('email') == '' || props.logout === true) ? (
  <><div className="duis" style={{marginLeft:"20px",marginRight: "10px",color:"white",padding:"1px 8px",lineHeight:"21px",borderRadius:"3px",fontSize:"16px",display:"flex"}}>
  <Link to="/login"><button type="button" className='btn btn-primary' style={{ marginRight:"10px" }}>Login</button></Link>
  <Link to="/register"><button type="button" className='btn btn-primary'>Register</button></Link>
</div></>) : (
 <><div className="duis" style={{marginLeft:"20px",marginRight: "10px",color:"white",padding:"1px 8px",lineHeight:"21px",borderRadius:"3px",fontSize:"16px"}}>

<Link to="/wallet"><button type="button" className='btn btn-success' style={{width:"100px"}}>₹ {props.wallet}</button></Link>
</div><Link to="/logout"><button type="button" className='btn btn-primary'>Logout</button></Link></>)}

                        
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </div>
    </>
  )
}
