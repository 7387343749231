import React, { useState, useEffect } from 'react'
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Color from "./Components/Color";
import Login from "./Components/Login";
import Register from "./Components/Register";
import Logout from "./Components/Logout";
import Wallet from "./Components/Wallet";

function App() {
  const [mesg, setMesg] = useState("");
  const [logout, setLogout] = useState(false);
  const [data,setData] = useState([]);
  
  const initialTimer = localStorage.getItem("timer") ?? 60;
  const timeoutId = React.useRef(null);
  const [timer, setTimer] = React.useState(initialTimer);
  

  const countTimer = React.useCallback(() => {
    if (timer <= 0) {
      localStorage.clear("timer");
    } else {
      setTimer(timer - 1);
      localStorage.setItem("timer", timer);
    }
  }, [timer]);

  
  useEffect(() => {
    timeoutId.current = window.setTimeout(countTimer, 1000);
    return () => window.clearTimeout(timeoutId.current);
    
  }, [timer, countTimer]);

  if(timer === 0)
    {
        axios
        .get(`http://onkar.world/react_apis/period.php`)
        .then(function (response) {
          console.log(response.data);
          sessionStorage.setItem("period",response.data);
        })
        .catch(function (error) {
        });
      setTimer(60);
      
    }



// login user
const loginUser = (e) => {
  e.preventDefault();
    const data = {
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
    };

    axios
      .post("http://onkar.world/react_apis/login.php", JSON.stringify(data))
      .then(function (response) {
        if(response.data.status ==1){
        sessionStorage.setItem("email", response.data.email);
        sessionStorage.setItem("profileid", response.data.profileid);
        sessionStorage.setItem("name", response.data.name);
        sessionStorage.setItem("wallet", response.data.wallet);
        setMesg({
          msg: response.data.msg,
          typ: response.data.status === "1" ? "success" : "danger",
        });
        setLogout(false);
      }
      else{
        setMesg({
          msg: response.data.msg,
          typ: "danger",
        });
      }
      })
      .catch(function (error) {
        
        setMesg({
          msg: error,
          typ: "danger",
        });
      });
  
};
// login user



// wallet recharge
const walletRecharge = (e) => {
  e.preventDefault();
  
    const datas = {
      amount: document.getElementById("amount").value,
      profileid: sessionStorage.getItem("profileid")
    };

    axios
      .post("http://onkar.world/react_apis/wallet_recharge.php", JSON.stringify(datas))
      .then(function (response) {
        if(response.data.status ==1){
        setMesg({
          msg: response.data.msg,
          typ: response.data.status === "1" ? "success" : "danger",
        });
      }
      else{
        setMesg({
          msg: response.data.msg,
          typ: "danger",
        });
      }
      })
      .catch(function (error) {
        setMesg({
          msg: error,
          typ: "danger",
        });
      });

    getWallet();
    
};
// wallet recharge

const getWallet = () =>{
  axios
        .get(`http://onkar.world/react_apis/wallet_list.php?profileid=${sessionStorage.getItem('profileid')}`)
        .then(function (response) {
          setData(response.data.res);
        })
        .catch(function (error) {
        });
}




const change_logout_menu =()=>{
  setLogout(true);
  setMesg({
    msg: 'Logout Successfully!!!',
    typ: "success",
  });
}



  return (
   
    <BrowserRouter>
      <Header logout={logout} wallet={sessionStorage.getItem('wallet')}/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/color" element={<Color timer={timer}/>} />
          <Route exact path="/login" element={<Login loginUser={loginUser} mesg={mesg} />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/logout" element={<Logout change_logout_menu={change_logout_menu}/>} />
          <Route exact path="/wallet" element={<Wallet data={data} getWallet={getWallet} walletRecharge={walletRecharge} mesg={mesg}/>} />
        </Routes>
        <Footer logout={logout}/>
      </BrowserRouter>


  );
}

export default App;
