import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Link } from "react-router-dom";

export default function Home() {

    // const upername = {
    //     position: "absolute",
    //         background: "brown",
    //         fontSize: "11px",
    //         top: "9px",
    //         marginLeft: "-9px",
    //         color: "white",
    //         width: "16px",
    //         height: "16px",
    //         borderRadius: "50%",
    //         lineHeight: "16px",
    //         textAlign: "center"
    //   };

  return (
    <>
    <div className="humanbeing">
      
    <div className="slider-section">
        <div className="container">
            <div className="owl-theme">
                <div className="item">
                    <img src="img/Banner_20240413184012f1tp.png" alt=""/>
                </div>
               
            </div>
        </div>
    </div>
    <div className="buttons">
        <div className="container">
            <div className="row g-2">
                <div className="col-md-6 col-6">
                    <Link to="/wallet"><button className="btn-theme"><span
                            style={{marginRight: "5px"}}><i className="fa-solid fa-plus"></i></span>Add Money</button></Link>
                </div>
                <div className="col-md-6 col-6">
                <Link to="/color"><button  className="btn-theme">Play game</button></Link>
                </div>
                {/* <div className="col-md-4 col-4">
                    <button href="" className="btn-theme"><span style={{marginRight: "5px"}}><i
                                className="fa-solid fa-coins"></i></span>Withdraw</button>
                </div> */}
            </div>
        </div>
    </div> 

    
    <div className="container" style={{paddingBottom: "80px"}}>
        <div className="row g-2">
        <div className="col-md-12 col-12">
            <div className="slider-frist" style={{marginTop: "10px"}}>
                <Link to="/color"><img src="img/91-club-colour-prediction-ac4-logo" alt=""/></Link>
            </div>
        </div>
        
        </div>
    </div>
    </div>
    </>
  )
}
