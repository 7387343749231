import React from 'react'
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <>
    <div className="humanbeing">
         <div className="footer">
        <div className="container">
            <div className="diss">
                <div className="row">
                     <div className="col">
                        <Link to="/">
                            <div className="icond-f">
                                <i className="fa-solid fa-home"></i>
                                <div className="content">
                                    <h6>Home</h6>
                                </div>
                            </div>
                        </Link>
                        </div>
                    <div className="col">
                        <Link to="/color">
                            <div className="icond-f">
                                <i className="fa-solid fa-rotate"></i>
                                <div className="content">
                                    <h6>Colorgame</h6>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col">
                        <Link to="/wallet">
                            <div className="icond-f">
                                <i className="fa-solid fa-coins"></i>
                                <div className="content">
                                    <h6>Wallet</h6>
                                </div>
                            </div>
                        </Link>
                    </div>

                    {(sessionStorage.getItem('email') === null || sessionStorage.getItem('email') == '' || props.logout === true) ? (
  <>
    <div className="col">
                        <Link to="/login">
                            <div className="icond-f">
                                <i className="fa fa-sign-in"></i>
                                <div className="content">
                                    <h6>Login</h6>
                                </div>
                            </div>
                        </Link>
                    </div>
  </>) : (
 <>
 <div className="col">
                        <Link to="/logout">
                            <div className="icond-f">
                                <i className="fa fa-sign-out"></i>
                                <div className="content">
                                    <h6>Logout</h6>
                                </div>
                            </div>
                        </Link>
                    </div>
 </>)}

                    
                   
                </div>
            </div>
        </div>
    </div> 
    </div>
    </>
  )
}
