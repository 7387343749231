import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Wallet(props) {
    const navigate = useNavigate();
    


      useEffect(() => {
        if(sessionStorage.getItem('email')===null)
            {
              navigate('/login');
            }

            props.getWallet();

      }, []);

      
  return (
    <>
     <div className="humanbeing">
   
   <div className="banner-slider">
       <div className="container">
           <div className="section" id="fufufufu">
           {props.mesg !== "" && (
         <div className={`alert alert-${props.mesg.typ}`} role="alert">
           {props.mesg.msg}
         </div>
       )}
               <form
         method="POST"
         onSubmit={props.walletRecharge}
       >
         
         <div className="mb-3">
           <input
             placeholder="Enter Amount"
             type="text"
             name="amount"
             id="amount"
             className="form-control"
             required
           />
        
         </div>

         <br />
         <button
           type="submit"
           id="submit-button"
           className="btn btn-primary"
         >
           Submit
         </button>
       </form>

       <div className="dubder">
                    <table style={{borderTop:"2px solid #0093E9 !important"}}>
                        <thead>
                        <tr style={{borderBottom:"1px solid gainsboro"}}>
                            <th>#</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Datetime</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.data.map((item,index) => (
                        <tr key={index+1} style={{textAlign:"center !important"}}>
                            <td>{index+1}</td>
                            <td>{item.wlt_amount}</td>
                            <td>{item.wlt_status === '1' ? "Pending" : item.wlt_status === '2' ? "Approved" : "Rejected"}</td>
                            <td>{item.wlt_datetime}</td>
                            
                        </tr>
                        ))}
                        </tbody>
                      

                    </table>

                </div>

           </div>

           
       </div>
   </div>

  
   </div>  
    </>
  )
}
