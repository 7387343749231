import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Tag } from 'primereact/tag';
import { FilterMatchMode } from 'primereact/api';
//import "primereact/resources/themes/nova-accent/theme.css";
import axios from "axios";

export default function Color(props) {
    const [show, setShow] = useState(false);
    const [num, setNum] = useState(false);
    const [cmoney, setMoney] = useState(false);
    const [ticking, setTicking] = useState(true);
    const [qty, setQty] = useState(1); 
    const [tamount, setTamount] = useState(10); 
    const [backg, setBackg] = useState(''); 
    const navigate = useNavigate();
    const [dta,setDta] = useState([]);
    const [orderdata,setOrderdata] = useState([]);
    const [ordermydata,setOrdermydata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [globalFilterValues, setGlobalFilterValues] = useState('');
    const [mesg, setMesg] = useState("");
    


    

  useEffect(() => {
    if(sessionStorage.getItem('email')===null)
      {
        navigate('/login');
      }  
      getRecords();
      getMyRecords();
      getDta();
  }, [props.timer]);

  
  const getRecords = () =>{
    axios
          .get(`http://onkar.world/react_apis/get_records.php`)
          .then(function (response) {
            setOrderdata(response.data.res);
            setLoading(false);
          })
          .catch(function (error) {
          });
  }
    const statusBodyTemplate = (orderdata) => {
        
        return <Tag style={
            (orderdata.result === '1' || orderdata.result === '3' || orderdata.result === '7' || orderdata.result === '9') ? { background:'green',width:"15px",height:"15px",borderRadius:"30px"} : (orderdata.result === '2' || orderdata.result === '4' || orderdata.result === '6' || orderdata.result === '8') ? { background:'red',width:"15px",height:"15px",borderRadius:"30px"} : { background:'violet',width:"15px",height:"15px",borderRadius:"30px"}}>&nbsp;</Tag>;
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        //'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onGlobalFilterChanges = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilterss(_filters);
        setGlobalFilterValues(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
            </div>
        );
    };
    const renderHeaders = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValues} onChange={onGlobalFilterChanges} placeholder="Search" />
                </IconField>
            </div>
        );
    };
    const header = renderHeader();
    const headers = renderHeaders();




    const getMyRecords = () =>{
        axios
              .get(`http://onkar.world/react_apis/get_my_records.php?profileid=${sessionStorage.getItem('profileid')}`)
              .then(function (response) {
                console.log(response);
                setOrdermydata(response.data.res);
                setLoading(false);
              })
              .catch(function (error) {
              });
      }

      const statusBodyMyTemplate = (ordermydata) => {
        
        return <Tag>{
            (ordermydata.status === '1') ?  ordermydata.amount : "0"}/-</Tag>;
    };

    const contractmoney = (ordermydata) => {
        
        return <Tag>{ordermydata.contractmoney*ordermydata.contractcount}/-</Tag>;
    };


    const [filterss, setFilterss] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        //'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

   



  const getDta = () =>{
    axios
          .get(`http://onkar.world/react_apis/get_records2.php?profileid=${sessionStorage.getItem('profileid')}`)
          .then(function (response) {
            setDta(response.data.res);
          })
          .catch(function (error) {
          });
  } 

  
  const handleShow = (color,money,val) => {
    setBackg(color);
    setNum(val);
    setMoney(money);
    setShow(true);
  }
  const closeModal = () => {
    setShow(false);
  }
  const incr = () =>{
    setQty(qty+1);
    var dd = (qty+1)*cmoney;
    setTamount(dd);
    
  }
  const decr = ()=>{
    if(qty>1)
    {
        setQty(qty-1);
        var dd = (qty-1)*cmoney;
        setTamount(dd);
    }
  }

  const submitGame = (e) => {
    e.preventDefault();
    
    const data = {
        period: sessionStorage.getItem("period"),
        contractmoney: document.getElementById("contractmoney").value,
        contractcount: document.getElementById("contractcount").value,
        selectnumber: num,
        amount: document.getElementById("total").value,
        profileid: sessionStorage.getItem('profileid')
      };
      
    axios.post('http://onkar.world/react_apis/entry.php', JSON.stringify(data))
.then(function (response) {
setMesg({
    msg:response.data.msg,
    typ:response.data.status === "1" ? "success" : "danger",
});
sessionStorage.setItem("wallet", response.data.wallet);
setShow(false);
})
.catch(function (error) {   
    
setMesg({
    msg:error,
    typ:"danger",
});
});
    
  };

  
  return (
    <>
    
      <div className="humanbeing">
    <div className="tab-content" id="myTabContent">
        
        <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab">
        {mesg !=='' &&
        <div className={`alert alert-${mesg.typ}`} role="alert">{mesg.msg}</div>
      } 
      
      <Modal show={show}>
        <Modal.Header style={{ background:backg,color:"#fff" }}>
          <Modal.Title>Join(Select {num})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
          
          <form method="post">
<input name="period" type="hidden" value={sessionStorage.getItem("period")}/>
<input name="selectnumber" type="hidden" value={num}/>
<div className="row">

 <div className="col-lg-12">
  <label className="form-label">Contract Money:</label>
  <div className="boxed">
   
   <input type="radio" name="contractmoney" value="10" id="contractmoney" onClick={() => handleShow(num,'10')} checked={cmoney === '10'}/>
   <label htmlFor="voiletcolor10">10</label>
    &nbsp;&nbsp;
   <input type="radio" name="contractmoney" value="100" id="contractmoney" onClick={() => handleShow(num,'100')} checked={cmoney === '100'}/>
   <label htmlFor="voiletcolor100">100</label>
   &nbsp;&nbsp;
   <input type="radio" name="contractmoney" value="1000" id="contractmoney" onClick={() => handleShow(num,'1000')} checked={cmoney === '1000'}/>
   <label htmlFor="voiletcolor1000">1000</label>

  </div>

 </div>

</div>

<div className="row">

 <div className="col-lg-12">

  <label className="form-label">Number:</label>

 </div>
<div  style={{display:"flex"}}>
 <div className="col-lg-4">
  <input type="number" name="contractcount" id="contractcount" className="form-control quantity qty2" value={qty} min="1" required/>
 </div>

 <div className="col-lg-1 col-xs-3 col-sm-3" style={{marginLeft:"4px"}}>
    <button type="button" className="btn btn-primary add2" onClick={incr}>+</button>
 </div>

 <div className="col-lg-1 col-xs-3 col-sm-3" style={{marginLeft:"4px"}}>
    <button type="button" className="btn btn-primary minus2" onClick={decr}>-</button>
 </div>
</div>
 

 <div className="col-lg-12" id="txtVoiletAmount">

    <input type="hidden" class='total' id="total" name="total" value={tamount} />
    <div>Total Contract Money is : <span className="total_amount" id="total_amount">{tamount}</span></div>
 </div>
</div>

</form>
          
          </>
        </Modal.Body>
        <Modal.Footer>
        <button name="submit" type="button" className='btn btn-primary' onClick={submitGame}>Confirm</button>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
            <div className="container">
                <div className="row" style={{marginTop: "16px"}}>
                    <div className="col-md-6 col-6">
                        <div className="drio">
                            <p style={{marginBottom:"5px",fontSize: "13px",fontWeight:"500"}}><span><i className="fa-solid fa-clock"></i></span>Period</p>
                            <h5>{sessionStorage.getItem("period")}</h5>
                        </div>
                    </div>
                    <div className="col-md-6 col-6">
                        <div className="drio">
                            <p style={{marginBottom:"5px",fontSize: "13px",fontWeight:"500"}}>Count Down</p>
                            <h5>{props.timer} sec</h5>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-md-4 row-cols-4 g-1" id="colorbutton"
                    style={{marginBottom: "10px",justifyContent:"space-between"}}>

                    <div className="col">
                        <button style={{backgroundColor:"#41d641"}}  onClick={() => handleShow('Green','10','Green')}>Join green</button>
                    </div>

                    <div className="col">
                        <button style={{backgroundColor:"#bc08ff"}}  onClick={() => handleShow('Violet','10','Violet')}>Join Violet</button>
                    </div>

                    <div className="col">
                        <button style={{backgroundColor:"red"}} onClick={() => handleShow('Red','10','Red')}>join Red</button>
                    </div>


                </div>
                <div className="row row-cols-md-5 row-cols-5 g-1" id="colorbutton">
                    <div className="col">
                        <button
                            style={{backgroundImage:"linear-gradient(155deg, #bc08ff 48%, #ff0606 32%, #ff0a0a 100%)"}} onClick={() => handleShow('Violet','10','0')}>0</button>
                    </div>
                    <div className="col">
                        <button style={{backgroundColor:"#41d641"}} onClick={() => handleShow('Green','10','1')}>1</button>
                    </div>
                    <div className="col">
                        <button style={{backgroundColor:"red"}} onClick={() => handleShow('Red','10','2')}>2</button>
                    </div>
                    <div className="col">
                        <button style={{backgroundColor:"#41d641"}} onClick={() => handleShow('Green','10','3')}>3</button>
                    </div>
                    <div className="col">
                        <button style={{backgroundColor:"red"}} onClick={() => handleShow('Red','10','4')}>4</button>
                    </div>
                    <div className="col">
                        <button
                            style={{backgroundImage:"linear-gradient(155deg, #bc08ff 48%, #ff0606 32%, #ff0a0a 100%)"}} onClick={() => handleShow('Violet','10','5')}>5</button>
                    </div>
                    <div className="col">
                        <button style={{backgroundColor:"red"}} onClick={() => handleShow('Red','10','6')}>6</button>
                    </div>
                    <div className="col">
                        <button style={{backgroundColor:"#41d641"}} onClick={() => handleShow('Green','10','7')}>7</button>
                    </div>
                    <div className="col">
                        <button style={{backgroundColor:"red"}} onClick={() => handleShow('Red','10','8')}>8</button>
                    </div>
                    <div className="col">
                        <button style={{backgroundColor:"#41d641"}} onClick={() => handleShow('Green','10','9')}>9</button>
                    </div>

                </div>
            </div>
            <div className="main-game-secyiom">
                <div className="sach">
                    <p><span><i className="fa-solid fa-trophy"></i></span>Parity Record</p>

                </div>
                <div className="dubder">
                <DataTable dataKey="id" filters={filters} paginator rows={10} 
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" value={orderdata} filterDisplay="row" loading={loading}
         header={header}>
                    <Column sortable field="period" header="Period"></Column>
                    <Column field="openprice" header="Price"></Column>
                    <Column field="result" header="Result"></Column>
                    <Column header="Color" body={statusBodyTemplate}></Column>
                </DataTable>

                </div>
                
            </div>
            <div className="main-game-secyiom">
                <div className="sach">
                    <p><span><i className="fa-solid fa-trophy"></i></span>My
                        Parity Record</p>

                </div>
                <div className="dubder">
                <DataTable dataKey="id" filters={filterss} paginator rows={10} 
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" value={ordermydata} filterDisplay="row" loading={loading}
         header={headers}>
                    <Column sortable field="period" header="Period"></Column>
                    <Column field="selectnumber" header="Select Number"></Column>
                    <Column field="result" header="Result"></Column>
                    <Column header="Bet Amount" body={contractmoney}></Column>
                    <Column header="Win amount" body={statusBodyMyTemplate}></Column>
                </DataTable>

                </div>
              
            </div>
        </div>
      <br/><br/><br/><br/>
    </div>
    </div>
    </>
  )
}
